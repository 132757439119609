<template>
  <v-row class="pa-4" align="center">
    <v-col cols="12">
      <h1>Thimble.io IDE</h1>
      <h4>Version: {{version}}</h4>
      <p>
        The Thimble.io IDE is a web-based programing interface for programming microcontrollers,
        like an Arduino or ESP, and proxies process of compiling/uploading code from a remote server to the client.
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex';
import { version } from '../../package.json';

export default {
  data() {
    return {
      version,
    };
  },
  methods: {
    ...mapMutations(['toggleDonateMenu']),
  },
};
</script>
